import {Container} from "@mui/material"
import React from "react"
import {Route, Routes} from "react-router-dom"
import {RecentArticles} from "../../views/RecentArticles/RecentArticles"
import {TagArticles} from "../../views/TagArticles/TagArticles"
import {BestArticles} from "../../views/BestArticles/Articles"
import {Navigation} from "./navigation/Navigation"
import {useKeycloak} from "@react-keycloak/web";
import {Subscriptions} from "../../views/Subscriptions/Subscriptions";
import {MyStream} from "../../views/MyStream/MyStream"
import ProfileTabs from "../../views/Profile/ProfileTabs"

export function Layout() {
  const {initialized} = useKeycloak();

  if (!initialized) {
    return <></>;
  }

  return (
    <Container maxWidth="lg">
      <Navigation/>
      <Routes>
        <Route path="/" element={<BestArticles/>}/>
        <Route path="/page/:page" element={<BestArticles/>}/>
        <Route path="/recent/" element={<RecentArticles/>}/>
        <Route path="/recent/page/:page" element={<RecentArticles/>}/>
        <Route path="/my/" element={<MyStream/>}/>
        <Route path="/my/page/:page" element={<MyStream/>}/>
        <Route path="/subscriptions/" element={<Subscriptions/>}/>
        <Route path="/tag/:tag" element={<TagArticles/>}/>
        <Route path="/profile/" element={<ProfileTabs/>}/>
      </Routes>
    </Container>
  )
}
