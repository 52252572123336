import Chip from '@mui/material/Chip';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserSettings } from '../../UserSettings/useUserSettings';
import { ArticlesApi } from '../../services/articles.api';
import { apiConfig } from "../../services/api.config"
import { useApplicationSettings } from '../../settings/useApplicationSettings';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { Grid, Snackbar } from '@mui/material';

export default function Tags(props: { articleId: number, tags: string[] }) {

    const navigate = useNavigate();
    let appSettings = useApplicationSettings();
    const userSettings = useUserSettings();
    const articlesApi = new ArticlesApi(appSettings, apiConfig);
    const [newTag, setNewTag] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    var handleDelete = (value: string) => {
        const index = props.tags.indexOf(value, 0);
        props.tags.splice(index, 1);
        articlesApi.updateTags(props.articleId, props.tags)
            .then(() => {
                setSnackbarMessage('Tag removed');
                setSnackbarOpen(true);
            })
            .catch(() => {
            })
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    var input = undefined;
    if (userSettings.isArticlesModerator) {
        input =
            <Grid item>
                <TextField id="standard-basic" label="new tag" size="small" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewTag(event.target.value);
                }} />
                <IconButton aria-label="share" onClick={() => {
                    props.tags.push(newTag);
                    articlesApi.updateTags(props.articleId, props.tags).then(() => {
                        setSnackbarMessage('Tags updated');
                        setSnackbarOpen(true);
                    });
                }}>
                    <SendIcon />
                </IconButton>
            </Grid >
    }

    return (
        <>
            {
                props.tags.map(tag => {
                    return <Grid item><Chip label={'#' + tag} variant="outlined" key={tag} onClick={() => navigate('/tag/' + tag)}
                        onDelete={userSettings.isArticlesModerator ? () => handleDelete(tag) : undefined}
                    /></Grid>
                })
            }

            {
                input
            }
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
            />
        </>)

}