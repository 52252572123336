export interface UserSettings {
  subscribedChannels: number[];
  isArticlesModerator: boolean;
  updateUserSettings: (key: keyof UserSettings, newValue: any) => void;
}

export const UserSettingsDefault: UserSettings = {
  subscribedChannels: [],
  isArticlesModerator: false,
  updateUserSettings: () => {
  }
};
