import {AxiosResponse} from "axios";
import {Api} from "./api";
import {ArticlesResponse} from "./dto/articles-response";
import {Tag} from "./dto/tag";
import { TagsResponse } from "./dto/tags-response";
import { Language } from "./dto/language";


export class ArticlesApi extends Api {

  public getArticles = (page: number): Promise<AxiosResponse<ArticlesResponse>> => {
    return this.get<ArticlesResponse>('/articles/best/pl?page=' + page);
  }

  public getRecentArticles = (page: number): Promise<AxiosResponse<ArticlesResponse>> => {
    return this.get<ArticlesResponse>('/articles/pl?page=' + page);
  }

  public getTagArticles = (tag: string): Promise<AxiosResponse<ArticlesResponse>> => {
    return this.get<ArticlesResponse>('/articles/tag/' + tag);
  }

  public getNewsByChannelIds = (page: number, channelIds: number[]): Promise<AxiosResponse<ArticlesResponse>> => {

    return this.post(
      `/articles/selected-channels?page=${page}`,
      {
        "channelIds": channelIds
      });
  }

  public markAsRead = (articleId: number): Promise<AxiosResponse<any>> => {
    return this.post(
      `/articles/${articleId}/read`,
      null
    );
  }

  public updateTags = (articleId: number, newTags: string[]): Promise<AxiosResponse<any>> => {
    const tagObjects = newTags.map(value => {
      return {"name": value} as Tag;
    })

    return this.patch(
      `/articles/${articleId}/tags`,
      tagObjects
    );
  }

  public hide = (articleId: number): Promise<AxiosResponse<any>> => {
    return this.patch(
      `/articles/${articleId}/hide`,
      null
    );
  }

  public getTags = (page: number): Promise<AxiosResponse<TagsResponse>> => {
    return this.get<TagsResponse>('/articles/tags?page=' + page);
  }

  public updateTagStatus = (tagName: string, action: string): Promise<AxiosResponse<any>> => {
    return this.put(`/articles/tags/name/${tagName}/` + action);
  }

  public getLanguages = (): Promise<AxiosResponse<Language[]>> => {
    return this.get<Language[]>('/articles/languages');
  }

}
