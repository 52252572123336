import { Button, Pagination, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react"
import { apiConfig } from "../../services/api.config";
import { useApplicationSettings } from "../../settings/useApplicationSettings";
import { ArticlesApi } from "../../services/articles.api";
import { Tag } from "../../services/dto/tag";
import { useNavigate } from "react-router-dom";


export function Tags() {
  const [tags, setTags] = useState<Tag[]>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  let settings = useApplicationSettings();
  let keycloak = useKeycloak();
  const user = keycloak.keycloak.subject;
  const navigate = useNavigate();

  const handleActionClick = (tagName: string, status: string) => {
    const articlesAPI = new ArticlesApi(settings, apiConfig);

    const action = status === 'ENABLED' ? 'disable' : 'enable';

    articlesAPI.updateTagStatus(tagName, action).then(() => {
      setSnackbarOpen(true);
      articlesAPI.getTags(1).then(response => {
        setTags(response.data.data as Tag[]);
      }).catch();
    });

  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const articlesAPI = new ArticlesApi(settings, apiConfig);
    articlesAPI.getTags(page).then(response => {
      setTags(response.data.data as Tag[]);
      setTotalPages(Math.floor(response.data.totalElements / 20));
    }).catch();


  }, [settings, user, page])

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags?.map((tag) => (
              <TableRow
                key={tag.name}
                sx={{ '&:last-child td, &:last-schild th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" onClick={() => navigate('/tag/' + tag.name)}>
                  {tag.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {tag.status}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Button onClick={() => handleActionClick(tag.name, tag.status)}>{tag.status === 'ENABLED' ? 'Disable' : 'Enable'}</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination page={page} count={totalPages} onChange={handleChange} />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Tag changed"
      /></>
  )
}
