import Stack from "@mui/material/Stack";
import { Article } from "../../services/dto/article";
import ArticleCard from "./ArticleCard";
import Pagination from "@mui/material/Pagination";
import { ArticlesPlaceholder } from "../articles/articlesPlaceholder/ArticlesPlaceholder";

export default function ArticleList(props: { articles: Article[], page: number, onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void }) {

    if (!props.articles.length) {
        return (
            <ArticlesPlaceholder />
        );
    }

    return (
        <>
            <Stack style={{ textAlign: 'center' }} spacing={2}>
                {
                    props.articles.map(article => {
                        return <ArticleCard key={article.id} article={article} />
                    })
                }
            </Stack>
            <Stack style={{ textAlign: 'center' }} spacing={2} alignItems="center">
                <br />
                <Pagination page={props.page} count={10} onChange={props.onPageChange} />
                <br />
            </Stack>

        </>
    )
}