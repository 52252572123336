import {useEffect, useState, ReactNode} from 'react';
import {ApplicationSettingsContext} from './ApplicationSettingsContext';
import {ApplicationSettingsDefault, ApplicationSettings} from "./ApplicationSettings";

interface ApplicationSettingsProviderProps {
  children?: ReactNode
}

export function ApplicationSettingsProvider(props: ApplicationSettingsProviderProps) {
  const [settings, setSettings] = useState<ApplicationSettings>(ApplicationSettingsDefault);

  const updateSettings = (key: keyof ApplicationSettings, newValue: any) => {
    setSettings(oldState => {
      if (oldState[key] !== newValue) {
        const newState = {...oldState}
        newState[key] = newValue
        return newState
      } else {
        return oldState
      }
    })
  }

  useEffect(() => {
    fetch('/settings.json')
      .then(response => response.json())
      .then((settings: ApplicationSettings) => {
        setSettings({
          ...settings,
          updateApplicationSettings: updateSettings
        });
      });
  }, []);

  return (
    <ApplicationSettingsContext.Provider value={settings}>
      {props.children}
    </ApplicationSettingsContext.Provider>
  )
}
