import { IconButton, Snackbar } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import React, { useState } from "react";
import { apiConfig } from "../../services/api.config";
import { useApplicationSettings } from "../../settings/useApplicationSettings";
import { SubscriptionsApi } from "../../services/subscriptions.api";

export default function Subscribe(props: { channelId: number }) {

    let settings = useApplicationSettings();
    const subscriptionsApi = new SubscriptionsApi(settings, apiConfig);

    const handleSubscribeClick = () => {
        subscriptionsApi
            .subscribe({ userName: "", channelId: props.channelId })
            .then(() => {
                setOpen(true);
            });
    };

    const [open, setOpen] = useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <IconButton aria-label="add to favorites"
                onClick={handleSubscribeClick}>
                <FavoriteIcon />
            </IconButton>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Subscribed successfully"
            />
        </>
    )
}