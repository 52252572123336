import { Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Language } from "../../services/dto/language";
import { ArticlesApi } from "../../services/articles.api";
import { useApplicationSettings } from "../../settings/useApplicationSettings";
import { apiConfig } from "../../services/api.config";


export function Languages() {

    const [languages, setLanguages] = useState<Language[]>([]);
    let settings = useApplicationSettings();

    useEffect(() => {
        const articlesAPI = new ArticlesApi(settings, apiConfig);
        articlesAPI.getLanguages()
          .then(response => {
            setLanguages(response.data);
          })
      }, [settings])

    return (
        <Stack sx={{ mb: 5 }} spacing={2}>
            <Paper>Languages
                {languages.map((lang) => (
                    <div>{lang.name}</div>
                ))}
            </Paper>
        </Stack>
    )
}