import React, { useEffect, useState } from "react"
import { apiConfig } from "../../services/api.config"
import { Article } from "../../services/dto/article"
import { ArticlesApi } from "../../services/articles.api"
import { useApplicationSettings } from "../../settings/useApplicationSettings";
import ArticleList from "../../components/article/ArticleList"
import { useNavigate, useParams } from "react-router-dom";

export function RecentArticles() {

  let { page } = useParams<{ page: string }>();
  const [articles, setArticles] = useState([] as Article[])
  let settings = useApplicationSettings();
  const navigate = useNavigate();

  useEffect(() => {
    const articlesApi = new ArticlesApi(settings, apiConfig);

    articlesApi.getRecentArticles((page ? +page : 1) - 1).then(response => {
      setArticles(response.data.articles);
    }).catch();
  }, [page, settings])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setArticles([]);
    navigate('/recent/page/' + value);
  };

  return <ArticleList articles={articles} page={page ? +page : 1} onPageChange={handleChange}/>
}
