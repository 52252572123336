import { useEffect, useState, ReactNode } from 'react';
import { UserSettingsContext } from './UserSettingsContext';
import { UserSettings, UserSettingsDefault } from "./UserSettings";
import { apiConfig } from '../services/api.config';
import { SubscriptionsApi } from '../services/subscriptions.api';
import { useApplicationSettings } from '../settings/useApplicationSettings';
import { useKeycloak } from '@react-keycloak/web';

interface UserSettingsProviderProps {
  children?: ReactNode
}

export function UserSettingsProvider(props: UserSettingsProviderProps) {

  const [settings, setSettings] = useState<UserSettings>(UserSettingsDefault);
  let appSettings = useApplicationSettings();
  const { keycloak } = useKeycloak();

  const updateSettings = (key: keyof UserSettings, newValue: any) => {
    setSettings(oldState => {
      if (oldState[key] !== newValue) {
        const newState = { ...oldState };
        newState[key] = newValue;
        return newState;
      } else {
        return oldState;
      }
    });
  }

  useEffect(() => {
    if (keycloak.authenticated) {
      const subscriptionsApi = new SubscriptionsApi(appSettings, apiConfig);
      subscriptionsApi.getSubscriptions()
        .then(subscriptions => {
          updateSettings("subscribedChannels", subscriptions.data);
        })

      updateSettings("isArticlesModerator", keycloak.hasRealmRole('articles_moderator'));

      setSettings(oldState => {
        return {
          ...oldState,
          updateSettings: updateSettings
        }
      });
    }
  }, [keycloak, keycloak.authenticated, appSettings]);

  return (
    <UserSettingsContext.Provider value={settings}>
      {props.children}
    </UserSettingsContext.Provider>
  )
}
