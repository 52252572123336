import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
// import { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Article } from '../../services/dto/article';
import { Button, Grid } from '@mui/material';
import { apiConfig } from "../../services/api.config";
import { useApplicationSettings } from '../../settings/useApplicationSettings';
import { ArticlesApi } from "../../services/articles.api";
import { useKeycloak } from "@react-keycloak/web";
import Tags from './Tags';
import Hide from './Hide';
import Share from './Share';
import Subscribe from './Subscribe';

// interface ExpandMoreProps extends IconButtonProps {
//   expand: boolean;
// }

// const ExpandMore = styled((props: ExpandMoreProps) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

export default function ArticleCard(props: { article: Article }) {
  const [expanded] = React.useState(false);
  let settings = useApplicationSettings();
  const articlesApi = new ArticlesApi(settings, apiConfig);
  const { keycloak } = useKeycloak();

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const markAsRead = () => {
    if (keycloak.authenticated) {
      articlesApi.markAsRead(props.article.id);
    }
  }

  const openInNewTab = (url: string) => {
    markAsRead();
    window.open(url, '_blank');
  };

  const subscribe = keycloak.authenticated ? <Subscribe channelId={props.article.channel.id} /> : <></>;

  return (
    <Card sx={{ maxWidth: 1145 }}>
      <CardHeader
        // avatar={
        //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //     R
        //   </Avatar>
        // }

        // action={
        //   <CardActions>
        //     <IconButton aria-label="settings">
        //       <MoreVertIcon />
        //     </IconButton>
        //   </CardActions>
        // }
        title={props.article.title}
        subheader={props.article.pubDate.toString() + " " + props.article.channel.name}
      />
      {
        props.article.imageLink ?
          <CardMedia
            component="img"
            height="194"
            image={props.article.imageLink}
            alt=" "
          />
          :
          <></>
      }

      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {props.article.description}
        </Typography>
      </CardContent>
      <CardActions >
        <Grid container spacing={1}>
          <Grid item xs={1}>
            {subscribe}
          </Grid>
          <Grid item xs={1}><Share url={props.article.link} /></Grid>
          <Grid item xs={1}><Hide articleId={props.article.id}></Hide></Grid>
          <Grid item xs={9}></Grid>
          <Tags articleId={props.article.id} tags={props.article.tags} />
        </Grid>
        {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        > */}
        {/* <ExpandMoreIcon /> */}
        {/* </ExpandMore> */}

        <Button size="small" onClick={() => openInNewTab(props.article.link)}>More</Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>

          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
