import { Button, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react"
import { apiConfig } from "../../services/api.config";
import { Subscription } from "../../services/dto/subscription";
import { SubscriptionsApi } from "../../services/subscriptions.api";
import { useApplicationSettings } from "../../settings/useApplicationSettings";
import { NewChannelSubscription } from "./NewChannelSubscription";
import { useNavigate } from "react-router-dom";
import { useUserSettings } from "../../UserSettings/useUserSettings";
import { Languages } from "../Profile/Languages";

export function Subscriptions() {
  const [userSubscriptions, setUserSubscriptions] = useState<Subscription[]>([] as Subscription[]);
  let settings = useApplicationSettings();
  let userSettings = useUserSettings();
  let keycloak = useKeycloak();
  const user = keycloak.keycloak.subject;
  const navigate = useNavigate();

  const languageSection = userSettings.isArticlesModerator ? (
    <Languages/>
  ) : null

  const handleSubscribeClick = (channelId: number) => {
    const subscriptionsAPi = new SubscriptionsApi(settings, apiConfig);
    subscriptionsAPi.unsubscribe(channelId).then(() => {
      setSnackbarOpen(true);
      subscriptionsAPi.getDetailedSubscriptions()
        .then(response => {
          setUserSubscriptions(response.data as Subscription[]);
        }).catch(() => {
          navigate('/');
        });
    });
  }

  useEffect(() => {
    const subscriptionsAPi = new SubscriptionsApi(settings, apiConfig);
    subscriptionsAPi.getDetailedSubscriptions()
      .then(response => {
        setUserSubscriptions(response.data as Subscription[]);
      })
      .catch(() => {
        navigate('/');
      });


  }, [settings, user, navigate])

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      {languageSection}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {/* <TableCell>Description</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userSubscriptions.map((subscription) => (
              <TableRow
                key={subscription.channel.title}
                sx={{ '&:last-child td, &:last-schild th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {subscription.channel.title}
                </TableCell>
                {/* <TableCell component="th" scope="row"> */}
                {/* {subscription.channel.description} */}
                {/* </TableCell> */}
                <TableCell component="th" scope="row">
                  <Button onClick={() => handleSubscribeClick(subscription.channel.id)}>Remove</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <NewChannelSubscription />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Subscription removed"
      /></>

    // <div>
    //     {
    //         userSubscriptions.map(subscription => {
    //             return <div>
    //                 {subscription.id}
    //                 {subscription.channel.id}
    //                 {subscription.channel.title}
    //                 {subscription.channel.description}
    //                 </div>
    //         })
    //     }
    // </div>
  )
}
