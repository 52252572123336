export interface ApplicationSettings {
  apiBaseUrl: string;
  updateApplicationSettings: (key: keyof ApplicationSettings, newValue: any) => void;
}

export const ApplicationSettingsDefault: ApplicationSettings = {
  apiBaseUrl: "https://reader.siof.pl/api/",
  updateApplicationSettings: () => {
  },
};
