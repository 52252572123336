import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { apiConfig } from "../../services/api.config";
import { SubscriptionsApi } from "../../services/subscriptions.api";
import { useApplicationSettings } from "../../settings/useApplicationSettings";

export function NewChannelSubscription() {

    const [type, setType] = React.useState('YOUTUBE');
    const [url, setUrl] = React.useState('');
    let settings = useApplicationSettings();

    const handleChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
    };

    const subscriptionsAPi = new SubscriptionsApi(settings, apiConfig);

    const handleSubscribe = () => {
        subscriptionsAPi.subscribeNewChannel({
            language: "PL",
            url: url,
            type: type,
            tags: []
        }).then(response => { });
    }

    const onChangeUrl = (value: string) => {
        if (type === 'YOUTUBE') {
            setUrl('https://www.youtube.com/@' + value);
        } else {
            setUrl(value);
        }
    }

    const adornment = type === 'YOUTUBE' ? {
        startAdornment: <InputAdornment position="start">https://www.youtube.com/@</InputAdornment>,
    } : {};


    return (
        <Paper elevation={3} sx={{ mt: 2, p: 2 }}>

            <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>Adding new channels</Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>You can subscribe yourself to any RSS feed or YT below. You will see
                your own content stream under "MY STREAM" section.
            </Typography>

            <Grid container
                spacing={2}
                rowSpacing={2}
                sx={{ m: 2 }}
            >
                <Grid xs={3}></Grid>
                <Grid xs={6}>
                    <FormControl size="small">
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Type"
                            onChange={handleChange}
                        >
                            <MenuItem value={'RSS'}>RSS</MenuItem>
                            <MenuItem value={'YOUTUBE'}>YT</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={3}></Grid>

                <Grid xs={3}></Grid>
                <Grid xs={6}>
                    <TextField
                        id="standard-basic"
                        InputProps={adornment}
                        label="URL"
                        variant="standard"
                        sx={{ mt: 4, mb: 4 }}
                        onChange={(event: any) => {
                            onChangeUrl(event.target.value);
                        }}
                    />
                </Grid>
                <Grid xs={3}></Grid>

                <Grid xs={3}></Grid>
                <Grid xs={6}>
                    <Button variant="contained" onClick={handleSubscribe} >Add subscription</Button>
                </Grid>
                <Grid xs={3}></Grid>
            </Grid>
        </Paper>
    );
}