import { IconButton, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ArticleCard from "../../components/article/ArticleCard"
import { ArticlesPlaceholder } from "../../components/articles/articlesPlaceholder/ArticlesPlaceholder"
import { apiConfig } from "../../services/api.config"
import { Article } from "../../services/dto/article"
import { ArticlesApi } from "../../services/articles.api"
import { useApplicationSettings } from "../../settings/useApplicationSettings";
import RemoveIcon from '@mui/icons-material/Delete';
import { useUserSettings } from "../../UserSettings/useUserSettings"

export function TagArticles() {

  const [articles, setArticles] = useState([] as Article[])
  let { tag } = useParams<{ tag: string }>();
  let settings = useApplicationSettings();
  let userSettings = useUserSettings();
  const navigate = useNavigate();

  useEffect(() => {
    const articlesApi = new ArticlesApi(settings, apiConfig);

    articlesApi.getTagArticles(tag!).then(response => {
      setArticles(response.data.articles);
    }).catch();
  }, [tag, settings])

  if (!articles.length) {
    return (
      <ArticlesPlaceholder />
    );
  }

  const handleChangeStatus = () => {
    const articlesAPI = new ArticlesApi(settings, apiConfig);

    if(!tag) return;
    
    articlesAPI.updateTagStatus(tag, 'disable').then(() => {
      navigate('/');
    });

  }

  const manageSection = userSettings.isArticlesModerator ? 
    <>
      <IconButton aria-label="disable"
        onClick={handleChangeStatus}>
        <RemoveIcon />
      </IconButton>
    </> : <></>

  return (
    <>
      <Stack spacing={2}>
        {manageSection}
        {
          articles.map(article => {
            return <ArticleCard key={article.id} article={article} />
          })
        }
      </Stack>
    </>
  )

}
