import {ReactKeycloakProvider} from '@react-keycloak/web';
import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import {Layout} from './components/layout/Layout';
import keycloak from './services/keycloak';
import {ApplicationSettingsProvider} from "./settings/ApplicationSettingsProvider";
import {createTheme, ThemeProvider} from '@mui/material';
import {UserSettingsProvider} from "./UserSettings/UserSettingsProvider";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <ReactKeycloakProvider authClient={keycloak}>
        <React.StrictMode>
          <ApplicationSettingsProvider>
            <UserSettingsProvider>
              <Router>
                <div className="App">
                  <Layout/>
                </div>
              </Router>
            </UserSettingsProvider>
          </ApplicationSettingsProvider>
        </React.StrictMode>
      </ReactKeycloakProvider>
    </ThemeProvider>
  );
}

export default App;
