import IconButton from "@mui/material/IconButton";
import ShareIcon from '@mui/icons-material/Share';
import { Snackbar } from "@mui/material";
import React, { useState } from "react";

export default function Share(props: { url: string }) {
    const [open, setOpen] = useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <IconButton aria-label="share" onClick={() => {
                navigator.clipboard.writeText(props.url + ' - Found on https://reader.siof.pl/ <3');
                setOpen(true);
            }}>
                <ShareIcon />
            </IconButton>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Link copied to clipboard"
            />
        </>
    )
}