import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { Subscriptions } from "../Subscriptions/Subscriptions";
import Profile from "./Profile";
import { Tags } from "../Tags/Tags";
import { useUserSettings } from "../../UserSettings/useUserSettings";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ProfileTabs() {
    const [currentIndex, setValue] = useState(1);
    let userSettings = useUserSettings();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const profileTab = userSettings.isArticlesModerator ? (
        <Tab label="Profile" {...a11yProps(0)} />
    ) : null

    const tagsTab = userSettings.isArticlesModerator ? (
        <Tab label="Tags" {...a11yProps(2)} />
    ) : null

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentIndex} onChange={handleChange} aria-label="basic tabs example">
                    {profileTab}
                    <Tab label="Subscriptions" {...a11yProps(1)} />
                    {tagsTab}
                </Tabs>
            </Box>
            <TabPanel value={currentIndex} index={0}>
                <Profile />
            </TabPanel>
            <TabPanel value={currentIndex} index={1}>
                <Subscriptions />
            </TabPanel>
            <TabPanel value={currentIndex} index={2}>
                <Tags />
            </TabPanel>
        </Box>
    );

}
