import Keycloak from 'keycloak-js'

const keycloakConfig = {
    url: 'https://auth.siof.pl',
    realm: 'aggregator',
    clientId: 'aggregator-ui'
}

const keycloak = Keycloak(keycloakConfig);
export default keycloak
