export const apiConfig = {
  returnRejectedPromiseOnError: true,
  withCredentials: false,
  timeout: 30000,
  // baseURL: "https://reader.siof.pl/api/", // defined in settings.json
  // headers: {
  //     common: {
  //         "Cache-Control": "no-cache, no-store, must-revalidate",
  //         Pragma: "no-cache",
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //     },
  // },

}
