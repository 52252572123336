import {Skeleton} from "@mui/material";

export function ArticlesPlaceholder() {

  return (
    <>
      <Skeleton/>
      <Skeleton height={200}/>

      <Skeleton/>
      <Skeleton height={200}/>

      <Skeleton/>
      <Skeleton height={200}/>

      <Skeleton/>
      <Skeleton height={200}/>
    </>
  )
}
