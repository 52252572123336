import { useKeycloak } from "@react-keycloak/web";
import { useUserSettings } from "../../UserSettings/useUserSettings";
import { Paper, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    overflow: 'auto',
    color: theme.palette.text.secondary,
  }));

export default function Profile() {
    let userSettings = useUserSettings();
    const { keycloak } = useKeycloak();

    const userName = keycloak.tokenParsed?.name;
    const token = userSettings.isArticlesModerator ? <Item><p>Your token is: {keycloak.token}</p></Item> : <></>
    const userInfo = userName ? <p>Hello {userName}!</p> : <p>Hello!</p>

    return (
        <Stack spacing={2}>
            <Item>{userInfo}</Item>
            {token}
        </Stack>
    );
}
