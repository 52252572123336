import React, { useEffect, useState } from "react"
import { apiConfig } from "../../services/api.config"
import { Article } from "../../services/dto/article"
import { ArticlesApi } from "../../services/articles.api"
import { useApplicationSettings } from "../../settings/useApplicationSettings";
import { useUserSettings } from "../../UserSettings/useUserSettings"
import ArticleList from "../../components/article/ArticleList"
import { useNavigate, useParams } from "react-router-dom";

export function MyStream() {

  const [articles, setArticles] = useState([] as Article[])
  let { page } = useParams<{ page: string }>();
  let settings = useApplicationSettings();
  let userSettings = useUserSettings();
  const navigate = useNavigate();
  
  useEffect(() => {
    const articlesApi = new ArticlesApi(settings, apiConfig);

    articlesApi.getNewsByChannelIds(page ? +page : 0, userSettings.subscribedChannels)
      .then(response => {
        setArticles(response.data.articles);
      })
      .catch(() =>
        navigate('/')
      );
  }, [page, settings, userSettings.subscribedChannels, navigate])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setArticles([]);
    navigate('/my/page/' + value);
  };

  return <ArticleList articles={articles} page={page ? +page : 1} onPageChange={handleChange} />
}
