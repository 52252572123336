import {AxiosResponse} from "axios";
import {Api} from "./api";
import {Subscription} from "./dto/subscription";
import {NewSubscription} from "./dto/userSubscription";
import { NewChannelSubscription } from "./dto/NewChannelSubscription";


export class SubscriptionsApi extends Api {

  public getDetailedSubscriptions = (): Promise<AxiosResponse<Subscription[]>> => {
    return this.get<Subscription[]>('/subscriptions/detailed/user');
  }

  public getSubscriptions = (): Promise<AxiosResponse<number[]>> => {
    return this.get<number[]>('/subscriptions/user');
  }

  public subscribe = (subscription: NewSubscription): Promise<AxiosResponse<any>> => {
    return this.post('/subscriptions/new', subscription);
  }

  public unsubscribe = (channelId: number): Promise<AxiosResponse<any>> => {
    return this.post('/subscriptions/delete', {channelId});
  }

  public subscribeNewChannel = (subscription: NewChannelSubscription): Promise<AxiosResponse<any>> => {
    return this.post('/subscriptions/newChannel', subscription);
  }
}
