import { Button, Menu, MenuItem } from "@mui/material"
import { useKeycloak } from "@react-keycloak/web";
import React from "react"
import { useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';

export function Navigation() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    keycloak.login();
  };

  const handleLogout = () => {
    keycloak.logout();
  };

  const subscriptionsButton =
    keycloak.authenticated ?
      <Button
        id="basic-button2"
        aria-controls="basic-menu2"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => navigate('/subscriptions/')}
      >
        Subscriptions
      </Button>
      : <></>

  const ownStream =
    keycloak.authenticated ?
      <Button
        id="basic-button2"
        aria-controls="basic-menu2"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => navigate('/my/')}
      >
        My stream
      </Button>
      : <></>

  // let name;
  // keycloak.loadUserProfile().then(profile => {
  //     name = profile.username;
  // })

  return (
    <div>

      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => navigate('/')}
      >
        Main page
      </Button>

      {ownStream}

      <Button
        id="basic-button2"
        aria-controls="basic-menu2"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => navigate('/recent/')}
      >
        New
      </Button>

      {subscriptionsButton}

      <Button
        id="user-menu"
        aria-controls="basic-menu2"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <PersonIcon />
      </Button>


      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {keycloak.authenticated ?

          (<>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
            <MenuItem onClick={() => { navigate('/profile/'); handleClose(); }}>Profile</MenuItem>
            {/* <MenuItem onClick={handleClose}>Account</MenuItem> */}
            </>
          )
          :
          <MenuItem onClick={handleLogin}>Login</MenuItem>}


      </Menu>
    </div>
  )
}
