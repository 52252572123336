import IconButton from "@mui/material/IconButton";
import RemoveIcon from '@mui/icons-material/Delete';
import React, { useState } from "react";
import { useApplicationSettings } from "../../settings/useApplicationSettings";
import { useUserSettings } from "../../UserSettings/useUserSettings";
import { ArticlesApi } from "../../services/articles.api";
import { apiConfig } from "../../services/api.config";
import Snackbar from "@mui/material/Snackbar";

export default function Hide(props: { articleId: number }) {

    let appSettings = useApplicationSettings();
    const userSettings = useUserSettings();
    const articlesApi = new ArticlesApi(appSettings, apiConfig);
    const [open, setOpen] = useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    var handleDelete = () => {
        articlesApi.hide(props.articleId).then(()=> {
            setOpen(true);
        })
    }

    if (!userSettings.isArticlesModerator) {
        return <></>
    }

    return (
        <>
            <IconButton onClick={handleDelete}>
                <RemoveIcon />
            </IconButton>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Article hidden permanently"
            />
        </>
    )
}